import React from 'react'
import PropTypes from 'prop-types'

import lincoln from '../images/lincoln_bio.jpeg'
import sharrief from '../images/sharrief_bio.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => { this.props.onCloseArticle() }}></div>

    return (
      <div id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>
        <article id="program" className={`${this.props.article === 'program' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="major">Trade Program</h1>
          <h2>Introduction</h2>
          <p>For select clients, Qyu Technologies Corporation (“Qyu Tech”) offers a direct-to-account trading service.
             With this service, Qyu Tech’s trades are executed directly in the brokerage account of the client,
             providing the client with full transparency into their account’s activity. Qyu Tech’s performance
             fees are invoiced to the client quarterly.
          </p>
          <p>Qyu Tech coordinates with two brokerage firms to offer this service. </p>
          <div className="companyInfo">
            <div className="item">
              <span><strong>Advanced Markets LTD</strong></span>
              <span><a href="https://advancedmarketsfx.com">advancedmarketsfx.com</a></span>
              <span><em>94 Solaris Avenue, Camana Bay, P.O. Box 1348, GrandCayman, KY1-1108, Cayman Islands</em></span>
            </div>
            <div className="item"><span><strong>Invast Global</strong></span>
              <span><a href="https://invast.com.au">invast.com.au</a></span>
              <span><em>Level 27, Aurora Place, 88 Phillip Street Sydney NSW 2000, Australia</em></span></div>
          </div>
          <br />
          <h2>Overview</h2>
          <p>
            Qyu Tech introduces the client to both Advanced Markets and Invast Global,
             after which the client deals directly with them to complete the account creation and standard KYC/AML processes.
             The client then enters into a binding CTA Service Agreement with Qyu Tech,
             hiring and authorizing the company to provide CTA services for the client’s brokerage account.
             Qyu Tech executes trades on behalf of the client according to this agreement using tools available on the Meta Trader 4 (MT4) platform.
          </p>
          <h2>Procedure</h2>
          <h3>Establish an account at a designed brokerage firm</h3>
          <p>
            Qyu Tech has elected to use both Advanced Markets and Invast Global based on in-depth research and trials of their trade software over the court of a year, and believes they are amongst the most professional brokerages in the market today. This cautious approach is necessary as brokerages in foreign exchange are not as heavily regulated as those in the US stock or commodity markets. There is no central government or regulatory body which requires foreign exchange brokerages to adhere to high standards of business conduct. Qyu Tech chose these two brokerage houses because of the steady reputation they have exhibited in our business dealings with them to date. They provide a solid trading platform, with respectable price continuity, minimal slippage allowances and a willingness to support our CTA arrangements, all important factors in choosing a brokerage partner for our clients.
          </p>
          <h3>Meet the requirements for establishing a custodial account</h3>
          <p>
            When opening an account with Qyu Tech’s designated brokerages, it is necessary to open a custodial bank account registered in the client’s name, separate from the brokerage firm’s own bank accounts, adding to the client’s protection. With a custodial account, in the event of a catastrophic situation such as bankruptcy of the brokerage, your capital is not considered a part of the brokerage firm’s assets. Instead, your capital is secured and insured by a top-tier bank.
          </p>
          <p><em>Advanced Markets partners with Macquarie for custodial accounts which requires a $250K USD account minimum and requires satisfying Macquarie’s compliance requirements.</em></p>
          <p><em>Invast Global partners with National Australia Bank for custodial accounts. A custodial account with Invast requires at least $5M USD on account, but their compliance process is a bit quicker than Macquarie’s.</em></p>
          <div className='companyInfo'>
            <div className='item'>
              <span><strong>Macquarie Group Limited</strong></span>
              <span><a href="https://macquarie.com">macquarie.com</a></span>
              <span><em>50 Martin Place, Sydney NSW 2000, Australia</em></span>
            </div>
            <div className='item'>
              <span><strong>National Australia Bank Limited</strong></span>
              <span><a href="https://nab.com.au">nab.com.au</a></span>
              <span><em>Level 1 800 Bourke Street, Docklands Melbourne Victoria 3008, Australia</em></span>
            </div>
          </div>
          <br />
          <h2>Advantages with Qyu Technologies CTA</h2>
          <ul className="advantages">
            <li>Unrestricted real-time access to trade activity</li>
            <li>No waiting for statement distributions</li>
            <li>No subscriptions or lock-in periods</li>
            <li>Maintain direct control of your capital</li>
            <li>Quarterly high-watermark</li>
            <li>30% performance-based incentive fee</li>
            <li>No management fees</li>
            <li>White-label friendly</li>
            <li>Added fund/portfolio diversification</li>
            <li>Tailored risk profiles</li>
          </ul>
          {close}
        </article>

        <article id="strategy" className={`${this.props.article === 'strategy' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Strategy</h2>
          <div className="quote"><p><em>If you asked me to distill trading down into its simplest form, I would say that it is a pattern recognition numbers game. We use market analysis to identify the patterns, define the risk, and determine when to take profits.</em></p>
            <p>- Mark Douglas, Trading in The Zone</p></div>
          <p>
            The Forex (foreign exchange) market is the largest financial market in the world, and the sheer size of the Forex market sets it apart from all other markets. The Forex market is estimated to have a daily trading volume of $4 Trillion USD, with most of the trading activity concentrated on a few major currency pairs. This dwarfs the dollar-per-dollar trading volume of all of the world’s stock markets combined, for which the average daily trading volume is only about $84 Billion USD. Targeting the world’s most-traded currencies, in the world’s most-traded market, provides Qyu Tech with the benefits of trading in a market that has extremely high liquidity, making it easy to seamlessly move into and out of positions without worrying about whether a buy or sell order can be filled quickly. Qyu Tech strength in trading, or Qyu Tech's “Edge”, is built on our exceptional understanding and analysis of the nuances of the US economy and our expert skill in dealing with financial markets. Since the US Dollar is the world’s official reserve currency, and all the world’s central banks keep the US Dollar on their books, our keen sense of the fundamental trading action and price movement of the US Dollar against the other major currencies is central to our trading operation’s success.
          </p>
          <hr></hr>
          <h3>Qyu Tech's trading system</h3>
          <p>
            The majority of our trade entries involve paring the US Dollar with one of the other seven major currencies. This trade paring strategy is based on the following facts about Qyu and the markets in which Qyu trades.
          </p>
          <ul>
            <li><strong>High liquidity</strong> allows us to seamlessly move into and out of positions.</li>
            <li><strong>Lost Costs</strong> incurred when trading the US Dollar due to the bid-ask spreads being the least expensive in the foreign exchange markets.</li>
            <li><strong>Exceptional Analysis</strong> of the US Dollar and the role it plays in the financial markets and world economy.</li>
            <li><strong>Expert Skill</strong> in trading the Forex markets based on our proprietary modeling of the markets’ behavior </li>
          </ul>
          <p>
            Since the businesses and governments of these eight countries participate in trading commodities such as natural resources, manufactured goods and other valuables between each other, they must also exchange each other’s currencies in order to buy or sell those valuables. As the supply and demand for the valuables fluctuate, so do their prices. As the prices for the valuables fluctuate, buying or selling a valuable from a country can require more or less of that country’s currency, thus also driving the supply and demand for that country’s currency.
            </p>
          <h4>
            Economy-driven currencies
          </h4>
          <p>
            For some of the pairs we trade the price is primarily driven by the relative economic health of the two countries involved, and not so much by the changing supply and demand for valuables. All countries construct and refine their fiscal policies in order to manage the health of their economies. In order for each country to promote its own economy’s health, they must keep a sharp eye on the fiscal decisions and economic behavior of the other countries so that they may react in a manner most beneficial to their own economy. For these countries, we observe how they react to each other in order to analyze the market behavior of their currencies. Of our seven currency pairs, the four pairs whose prices are primarily driven by economic relationships are:
          </p>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Symbol</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Japanese Yen</td>
                <td>USD/JPY</td>
              </tr>
              <tr>
                <td>British Pound</td>
                <td>GBP/USD</td>
              </tr>
              <tr>
                <td>Swiss Franc</td>
                <td>USD/CHF</td>
              </tr>
              <tr>
                <td>Euro</td>
                <td>EUR/USD</td>
              </tr>
            </tbody>
          </table>
          <h4>
            Commodity-driven currencies
          </h4>
          <p>
            For these three currency pairs the price is primarily driven by the supply and demand for commodities. This is due to Australia, New Zealand, and Canada being major commodity-producing countries. For this reason, they are greatly affected when the world economies slow down, and they are devastated during global recessions . While we do not trade the currencies of Emerging Markets, these 3 currency pairs give us an understanding of the health of EM currencies because when these 3 commodity-driven economies slow down we know that the affect is severely worse for the economies in Emerging Markets.
          </p>
          <p>The remaining three pairs are:</p>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Symbol</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Australian Dollar</td>
                <td>AUD/USD</td>
              </tr>
              <tr>
                <td>New Zealand Dollar</td>
                <td>USD/NZD</td>
              </tr>
              <tr>
                <td>Canadian Dollar</td>
                <td>USD/CAD</td>
              </tr>
            </tbody>
          </table>

          <p>
            Almost all of the world’s countries, even those whose currencies we don’t trade, are significantly dependent upon the US Dollar not just because it’s the official reserve currency of the world, but also because they must exchange their currency into US Dollars in order to purchase crude oil. The commonly used term “Petro-Dollar” encapsulates this significance. Through 2015 and into 2016 the Canadian Dollar has been in a free-fall relative to the US Dollar because of the falling crude oil prices. Canada is a major oil producing nation and when we organize our trading models for crude oil valuation to the US Dollar, it is the Canadian Dollar that we trade.
          </p>
          <p>
            The average person does not understand the great influence that trade agreements have on the world’s countries because only an elite few benefit directly from those trade relationships. Those elite few may argue that the masses also gain a benefit from these agreements, but those benefits are only through ancillary effects.
          </p>
          <p>
            Part of our success in the foreign exchange markets comes from our ability to understand and analyze all of these economic components and relationships. The other part of our success comes from our trading skill which is based on our expert understanding of market behavior.
          </p>
          {close}
        </article>

        <article id="tech" className={`${this.props.article === 'tech' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="major">Technologies</h1>
          <h2>Client management dashboard</h2>
          <p>(Under development)</p>
          <p>
            A modern financial services firm needs powerful tools to efficiently manage their clients' investment accounts
             and ensure their clients can easily access thier account information. We have developed a bespoke Client Management dashboard
             to power our business and we license this same technology for use by other firms. The software includes the following standard capabilities:
          </p>
          <ul>
            <li>Secure login and user management powered by <a href="https://www.okta.com">Okta</a></li>
            <li>Secure document templating and digital signatures powered by <a href="https://www.docusign.com">DocuSign</a></li>
            <li>Automatic account statement generation</li>
            <li>Autoamtic reporting and email distribution</li>
            <li>Detailed account transaction activity</li>
            <li>Detailed investment market activity</li>
            <li>Multi-user account access (delegated access)</li>
            <li>Modern web-based interface. Fully smartphone compatible</li>
            <li>In-transit and at-rest AES-256 bit encyption</li>
            <li>Administrative panel:
              <ul>
                <li>Client on-boarding</li>
                <li>Client account transaction administration (withdrawls/deposits)</li>
                <li>Client-specific agreement parameters (profit sharing, fees, statement periods, etc.)</li>
              </ul>
            </li>
            <li>Account manager porfolio panel:
              <ul>
                <li>Manage groups of client accounts</li>
                <li>Portfolio performance analysis with charts and customizable reports</li>
                <li>Client-relation tools such as automated email correspondence</li>
              </ul>
            </li>
            <li>White-Label ready</li>
            <li>Themable to fit firm-sepecific branding</li>
            <li>Customizable feature-set to accomodate firm-specific workflows and business processes</li>
          </ul>
          <h2>Cryptocurrency tools</h2>
          <p>(Under development)</p>
          <p>
            The biggest concern for a cryptocurrency holder or invester is security and accessibility.
             We have developed solutions for managing cryptocurrency that don't force you to make a choice between security and easily accessing your funds.
             You retain sole control of your account's private key, and our web-based interface makes it easy to check your balance, send and receive funds.
             Our interface makes signing transactions simple and straightforward, using our secure offline iPhone application.
          </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Contact</h2>
          <div>
            <strong>Mailing address</strong>
            <p>
              Qyu Technologies Corporation <br />
              1800 Collins Ave 8C <br />
              Miami, FL  33139 <br />
              United States
            </p>
            <strong>Email</strong>
            <p>
              <a href="mailto:info@qyutech.com">info@qyutech.com</a>
            </p>
            <strong>Phone</strong>
            <p>
              <a href="tel:+12143770380">+1 (214)377-0380</a>
            </p>
          </div>

          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Main