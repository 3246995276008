import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        
        <div className="content">
            <div className="inner">
                <h1>Qyu Technologies CTA</h1>
                <p>Foreign Exchange experts specializing in geo-economic analysis and technical trading strategies covering the US Dollar, Japanese Yen, Euro and British Pound.</p>
                <p><a href="https://www.nfa.futures.org/basicnet/Details.aspx?entityid=1xTwt5EbCtA%3d">NFA ID 0510516</a></p>
            </div>
        </div>
        <nav>
            <ul>                
                <li><span className="link" onClick={() => {props.onOpenArticle('strategy')}}>Strategy</span></li>
                <li><span className="link" onClick={() => {props.onOpenArticle('program')}}>Trade Program</span></li>
                <li><span className="link" onClick={() => {props.onOpenArticle('tech')}}>Technologies</span></li>
                <li><span className="link" onClick={() => {props.onOpenArticle('contact')}}>Contact</span></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
